<template>
  <div :class="['v-input', classes]">
    <div v-if="$slots.prepend" class="v-input__prepend">
      <slot name="prepend" />
    </div>
    <input
      ref="input"
      :type="inputType"
      :value="localValue"
      class="v-input__elem"
      v-bind="$attrs"
      :disabled="disabled"
      v-on="inheritListeners"
      @input="handleInput($event.target.value)"
      @focus="isFocused = true"
      @blur="isFocused = false"
    />
    <div v-if="$slots.append" class="v-input__append">
      <slot name="append" />
    </div>
    <button v-if="type === 'password'" type="button" class="v-input__button-switch" @click="togglePassword">
      {{ textOfSwitch }}
    </button>
  </div>
</template>

<script>
import createTextMaskInputElement from 'text-mask-core/src/createTextMaskInputElement'
import { TEXT_MASK_CONFIG_DEFAULT, TEXT_MASK_AVAILABLE_INPUT_TYPES } from '@/constants/textMask'

export default {
  name: 'VInput',

  inheritAttrs: false,

  props: {
    size: { type: String, default: '' },
    filled: { type: Boolean, default: false },
    type: { type: String, default: 'text' },
    isError: { type: Boolean, default: false },
    value: { type: [String, Number], default: '' },
    disabled: { type: Boolean, default: false },
    mask: { type: [String, Array, Function], default: null }
  },

  data() {
    return {
      textMask: null,
      localValue: '',
      isPasswordVisible: false,
      isFocused: false
    }
  },

  computed: {
    inputType() {
      if (this.type === 'password') {
        return this.isPasswordVisible ? 'text' : 'password'
      }
      return this.type
    },
    textOfSwitch() {
      return this.isPasswordVisible ? 'Скрыть' : 'Показать'
    },
    classes() {
      return [
        {
          'v-input--error': this.isError,
          'v-input--filled': this.filled,
          'v-input--disabled': this.disabled,
          'v-input--focused': this.isFocused
        },
        this.size && `v-input--size--${this.size}`
      ]
    },
    inheritListeners() {
      const { input: _input, ...restListeners } = this.$listeners
      return restListeners
    },
    isValidTypeForMask() {
      // see more: https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#known-issues
      return TEXT_MASK_AVAILABLE_INPUT_TYPES.includes(this.type)
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (this.textMask) {
          this.updateTextMaskValue(newValue)
        } else {
          this.localValue = newValue
        }
      }
    },
    mask() {
      this.bindTextMask()
    },
    type() {
      this.bindTextMask()
    }
  },

  mounted() {
    this.bindTextMask()
  },

  methods: {
    bindTextMask() {
      if (this.mask && this.isValidTypeForMask) {
        this.textMask = Object.freeze(
          createTextMaskInputElement({
            inputElement: this.$refs.input,
            // more options: https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#readme
            mask: this.mask,
            ...TEXT_MASK_CONFIG_DEFAULT
          })
        )

        // format value by mask and emit 'input' event
        this.updateTextMaskValue(this.localValue)
      } else {
        this.textMask = null
      }
    },
    updateTextMaskValue(value) {
      this.textMask.update(value)
      this.localValue = this.$refs.input.value

      // not fire 'input' event, if value is not changed
      if (this.localValue !== this.value) {
        this.$emit('input', this.localValue)
      }
    },
    handleInput(value) {
      if (this.textMask) {
        this.updateTextMaskValue(value)
      } else if (this.type === 'number') {
        this.localValue = value || null
        this.$emit('input', this.localValue)
      } else {
        this.localValue = value
        this.$emit('input', this.localValue)
      }
    },
    togglePassword() {
      this.isPasswordVisible = !this.isPasswordVisible
    },
    focus() {
      this.$refs.input.focus()
    }
  }
}
</script>
